import { isMobile } from '../globals'
import './DisplayRecipes.scss'

function DisplayRecipes(props) {
  if (props?.recipes.length < 0) {
    return null
  }

  function showPopupRecipe(event) {
    if (!event?.target?.attributes) {
      console.error('Event object is ivalid', event)
      return
    }

    const nameItem = event.target.attributes.getNamedItem('recipeid')
    let recipeId
    if (nameItem) {
      recipeId = nameItem.value
    } else {
      console.error('Could not find attribute recipeid of element', event.target.attributes)
      return null
    }

    // Emit event to popup
    const popupEvent = new CustomEvent('showpopuprecipe', {
      detail: {
        recipeid: recipeId,
        clientX: event.clientX,
        clientY: event.clientY,
        targetRect: event.target.getClientRects()[0]
      }
    })

    document.dispatchEvent(popupEvent)
  }

  // PENDING: only render when the a response is received NOT every time
  // the user press a key in the input search or when a tag is added/removed
  return (
    <article className={isMobile ? 'display-recipes mobile' : 'display-recipes'}>
      {props.recipes.map(r => (
        <div className='recipe-card mobile'>
          <h3>{r.recipe}</h3>
          <div className="ingredients">
            {r.ingredients.map(i => (
             <p className={props.highlight.has(i) ? 'highlight' : ''}>{`${i}`}</p>
            ))}
          </div>
          <p className={isMobile ? 'preparation mobile' : 'preparation'}>{r.preparation}</p><span recipeid={r.id} onClick={showPopupRecipe}>[more]</span>
        </div>
      ))}
    </article>
  )
}

export default DisplayRecipes
