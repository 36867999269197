import { readLocally, saveLocally } from '../globals'
import { useState, useEffect } from 'react'
import './ShoppingList.scss'

function ShoppingList(props) {
  const [list, updateList] = useState([])

  useEffect(() => {
    /*
     * We have 2 lists here, one passed via props and one saved on local
     * storage __gf_list. The one in local starage remembers the ingredients
     * available and the one passed via props the ingredients needed for
     * the recipes.
     *
     * We can have the following cases:
     *   - props list is empty then reset __gf_list
     *   - __gf_list is empty then copy over props.list to __gf_list as it is
     *   - Create a new list newList by iterating over list, if there is a
     *     corresponding entry in __gf_list reflect the status of that entry
     *     (checked or not checked by the user) in localList. Save newList
     *     in __gf_list.
     */
    const localList = readLocally('__gf_list')
    const srcList = [...props.list]
    syncronise(srcList, localList)
  }, [props])

  function syncronise(src, local) {
    if (local === null) {
      updateList(src)
      saveLocally('__gf_list', src)
      return
    }

    if (src.length === 0) {
      updateList([])
      saveLocally('__gf_list', [])
      return
    }

    // Go thru the source list src, if one of its item
    // is found in local list then replicate the state
    const syncList = src.map(si => {
      const found = local.find(li => li.name === si.name)
      if (found) {
        si.checked = found.checked || false
      }
      return si
    })
    updateList(syncList)
    saveLocally('__gf_list', syncList)
  }

  if (!props.list) {
    return (
      <div className="shopping-list">
        <h3>Shopping List</h3>
        <p>Not available</p>
      </div>
    )
  }

  if (list.length === 0) {
    return (
      <div className="shopping-list">
        <h3>Shopping List</h3>
        <p>Please select a meal</p>
      </div>
    )
  }

  function toggleChecked({ target }) {
    const saved = readLocally('__gf_list')
    const ingredient = target.attributes['name'].value
    const newList = saved.map(item => {
      if (item.name === ingredient) {
        item.checked = target.checked
      }
      return item
    })
    updateList(newList)
    saveLocally('__gf_list', newList)
  }

  return (
    <article className="shopping-list">
      <h3>Shopping List</h3>
      <ul>
        {list.map((item, id) =>
          <li key={id}>
            <input name={item.name} type="checkbox" onChange={toggleChecked} checked={item.checked}/>
            <span className={item.checked ? 'strike' : ''}>{`${item.quantity || ''} ${item.unit || ''} ${item.name || ''}`}</span>
          </li>
        )}
      </ul>
    </article>
  )
}

export default ShoppingList
