import { Link } from 'react-router-dom'

function Header(props) {
  return (
    <header>
      <Link className="logo" to='/'>Gaffel</Link>
      <p className="motto">Your weekly meal planner</p>
    </header>
  )
}

export default Header
