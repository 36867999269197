import { Route, Switch } from 'react-router-dom'

import HomePage from './pages/Home'
import Leftover from './pages/Leftover'
import Cookies from './pages/Cookies'

function App() {
  return (
    <Switch>
      <Route path='/' exact>
        <HomePage />
      </Route>
      <Route path='/leftover' exact>
        <Leftover />
      </Route>
      <Route path='/cookies' exact>
        <Cookies />
      </Route>
    </Switch>
  )
}

export default App;
