import { useEffect, useRef, useState, useCallback } from 'react'
import { BACKEND_URL } from '../globals'
import { isMobile } from '../globals'
import './RecipePopup.scss'

function RecipePopup(props) {
  const [top, setTop] = useState()
  const [right, setRight] = useState()
  const [bottom, setBottom] = useState()
  const [left, setLeft] = useState()
  const [visibility, setVisibility] = useState('hidden')
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [preparation, setPreparation] = useState('')
  const [ingredients, setIngredients] = useState([])
  const [serving, setServing] = useState()
  const [time, setTime] = useState()
  const [diet, setDiet] = useState()
  const [note, setNote] = useState()

  const popup = useRef()

  const handleShowRecipe = useCallback((event) => {
    const scrollX = ( window.pageXOffset !== undefined ) ?
    window.pageXOffset :
    (
      window.document.documentElement ||
      window.document.body.parentNode ||
      window.document.body
    ).scrollLeft

    const scrollY = ( window.pageYOffset !== undefined ) ?
    window.pageYOffset :
    (
      window.document.documentElement ||
      window.document.body.parentNode ||
      window.document.body
    ).scrollTop

    const position = computePopupPosition(event.detail.targetRect, 300, scrollX, scrollY)
    setTop(position.top)
    setRight(position.right)
    setBottom(position.bottom)
    setLeft(position.left)
    setVisibility('visible')
    setIsLoading(true)
    getRecipe(event.detail.recipeid)
  }, [])

  useEffect(() => {
    if (popup && popup.current) {
      document.addEventListener('showpopuprecipe', handleShowRecipe, false)
      return () => {
        document.removeEventListener('showpopuprecipe', handleShowRecipe, false)
      }
    }
  }, [handleShowRecipe])

  const computePopupPosition = (
  	targetRect,
    popupWidth = 300,
    scrollX,
    scrollY
  ) => {
  	let left, right, top, bottom
    const innerWidth = window.innerWidth
    const innerHeight = window.innerHeight

  	left = targetRect.left > ( innerWidth / 2 ) ?
  		( scrollX + targetRect.right - popupWidth ) :
  		( scrollX + targetRect.left )

  	if ( targetRect.top > ( innerHeight / 2 ) ) {
  		bottom = ( innerHeight - targetRect.top - scrollY )
  	} else {
  		top = ( scrollY + targetRect.bottom )
  	}
  	return { left, right, top, bottom }
  }

  const getRecipe = (recipeId) => {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 10000) // Wait 10 sec

    fetch(
      `${BACKEND_URL}/recipe/${recipeId}`,
      { signal: controller.signal }
    ).then(response => {
      clearTimeout(timeoutId)
      return response.json()
    }).then(data => {
      setIsLoading(false)
      setName(data.name)
      setPreparation(data.preparation)
      setIngredients(data.ingredients)
      setServing(data.serving)
      setTime(data.time)
      setDiet(data.diet)
      setNote(data.note)
    }).catch(error => {
      console.error('Could not retrieve the recipe')
      setIsLoading(false)
    })
  }

  const handleClose = (event) => {
    event.preventDefault()
    setVisibility('hidden')
  }

  const renderPreparation = (prep) => {
    if (!prep) {
      return(
        <div><p>Ooops.. Something broke here.</p>
        <p>Sorry, we cannot show the recipe!</p>
        </div>
      )
    }
    const broken = prep.split('\n')
    return (
      <div>
        {broken.map((p,i) => <p key={i}>{p}</p>)}
      </div>
    )
  }

  const renderIngredients = (ingr) => {
    if (!ingr) {
      return null
    }
    return (
      <ul>
        {ingr.map(i => <li key={i}>{i}</li>)}
      </ul>
    )
  }

  // Add 7 pixels for distancing the point of the popup from the recipe name
  const styles = {
    top: top ? (top > 0 ? top + 7 : top - 7) + 'px' : 'unset',
    right: right ? right + 'px' : 'unset',
    bottom: bottom ? (bottom > 0 ? bottom + 7 : bottom - 7) + 'px' : 'unset',
    left: left ? left + 'px' : 'unset',
    visibility: visibility
  }

  /*
     If top is undefined then the arrow must point down
     if bottom is undefiend then the arrow must point up
  */
  const popupArrow = top ? 'recipe-popup-arrow-up' : 'recipe-popup-arrow-down'

  if (isMobile) {
    return (
      <div className="overlay" style={{left: visibility === 'hidden' ? '100%' : 0}} ref={popup}>
        <div className="overlay-container">
          <div className="recipe-overlay-container" style={{ 'alignItems': isLoading ? 'center' : 'initial'}}>
            <div className="recipe-overlay-presentation" style={{ display: isLoading ? 'none' : 'flex' }} >
              <h4>{name}</h4>
              <div className="recipe-property-container">
                <div className="recipe-property">
                  <img alt="" width="18px" height="18px" src="icons/person_black_24dp.svg"/>
                  <span>{serving}</span>
                </div>
                <div className="recipe-property">
                  <img alt="" width="18px" height="18px" src="icons/time_24dp.svg"/>
                  <span>{`${time || 0} min`}</span>
                </div>
                {diet === 'vegetarian' && <div className="recipe-property">
                  <span>{`🌱`}</span>
                </div>}
              </div>
              {renderIngredients(ingredients)}
              {renderPreparation(preparation)}
              {note && <p className="note">{note}</p>}
            </div>
            <div className="spinner" style={{ display: isLoading ? 'block' : 'none' }} />
          </div>
          <div className="recipe-overlay-cmd-area">
            <img width="24px" height="24px" src="icons/close_black_24dp.svg" alt="Close" onClick={handleClose}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div ref={popup} className={popupArrow} style={styles}>
      <div className="recipe-popup-container">
        <div className="recipe-popup-presentation" style={{ display: isLoading ? 'none' : 'flex' }} >
          <h4>{name}</h4>
          <div className="recipe-property-container">
            <div className="recipe-property">
              <img alt="" width="18px" height="18px" src="icons/person_black_24dp.svg"/>
              <span>{serving}</span>
            </div>
            <div className="recipe-property">
              <img alt="" width="18px" height="18px" src="icons/time_24dp.svg"/>
              <span>{`${time || 0} min`}</span>
            </div>
            {diet === 'vegetarian' && <div className="recipe-property">
              <span>{`🌱`}</span>
            </div>}
          </div>
          {renderIngredients(ingredients)}
          {renderPreparation(preparation)}
          {note && <p className="note">{note}</p>}
        </div>
        <div className="spinner" style={{ display: isLoading ? 'block' : 'none' }} />
      </div>
      <div className="recipe-popup-cmd-area">
        <img width="24px" height="24px" src="icons/close_black_24dp.svg" alt="Close" onClick={handleClose}/>
      </div>
    </div>
  )
}

export default RecipePopup
