import { Fragment } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { isMobile } from '../globals'
import './Cookies.scss'

function Cookies() {

  window.scrollTo(0, 0)

  return isMobile
    ? (
        <Fragment>
          <Header />
          <h2>Cookies Policy</h2>
          <article className="cookies mobile">
            <p>This website sets and maintains only technical data necessary to provide enhanced functionality and personalisation.</p>
            <p>We do not sell any information to third parties.</p>
            <h3>Technical cookies</h3>
            <table className="cookies">
              <tbody>
                <tr>
                  <td><b>Domain</b></td>
                  <td>www.gaffel.dk</td>
                </tr>
                <tr>
                  <td><b>Token</b></td>
                  <td>__gf_consent</td>
                </tr>
                <tr>
                  <td><b>Type</b></td>
                  <td>Cookie</td>
                </tr>
                <tr>
                  <td><b>Purpose</b></td>
                  <td>Decide whether to show cookies popup message.</td>
                </tr>
                <tr>
                  <td><b>Lifespan</b></td>
                  <td>365 Days</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table className="cookies">
              <tbody>
                <tr>
                  <td><b>Domain</b></td>
                  <td>www.gaffel.dk</td>
                </tr>
                <tr>
                  <td><b>Token</b></td>
                  <td>__gf_plan</td>
                </tr>
                <tr>
                  <td><b>Type</b></td>
                  <td>Local Storage</td>
                </tr>
                <tr>
                  <td><b>Purpose</b></td>
                  <td>Allow users to save their customised weekly plan.</td>
                </tr>
                <tr>
                  <td><b>Lifespan</b></td>
                  <td>Forever</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <table className="cookies">
              <tbody>
                <tr>
                  <td><b>Domain</b></td>
                  <td>www.gaffel.dk</td>
                </tr>
                <tr>
                  <td><b>Token</b></td>
                  <td>__gf_list</td>
                </tr>
                <tr>
                  <td><b>Type</b></td>
                  <td>Local Storage</td>
                </tr>
                <tr>
                  <td><b>Purpose</b></td>
                  <td>Allow users to save their shopping list.</td>
                </tr>
                <tr>
                  <td><b>Lifespan</b></td>
                  <td>Forever</td>
                </tr>
              </tbody>
            </table>
          </article>
          <Footer />
        </Fragment>
      )
    : (
        <Fragment>
          <Header />
          <h2>Cookies Policy</h2>
            <article className="cookies">
              <p>This website sets and maintains only technical cookies necessary to provide enhanced functionality and personalisation.</p>
              <p>We do not sell any cookies information to third parties.</p>
              <h3>Technical cookies</h3>
              <table className="cookies">
                <thead>
                  <tr>
                    <td>Domain</td>
                    <td>Token</td>
                    <td>Type</td>
                    <td>Purpose</td>
                    <td>Lifespan</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>www.gaffel.dk</td>
                    <td>__gf_consent</td>
                    <td>Cookie</td>
                    <td>Decide whether to show cookies popup message.</td>
                    <td>365 days</td>
                  </tr>
                  <tr>
                    <td>www.gaffel.dk</td>
                    <td>__gf_plan</td>
                    <td>Local Storage</td>
                    <td>Allow users to save their customised weekly plan.</td>
                    <td>Forever</td>
                  </tr>
                  <tr>
                    <td>www.gaffel.dk</td>
                    <td>__gf_list</td>
                    <td>Local Storage</td>
                    <td>Allow users to save their shopping list.</td>
                    <td>Forever</td>
                  </tr>
                </tbody>
              </table>
              <br/>
            </article>
          <Footer />
        </Fragment>
      )
}

export default Cookies
