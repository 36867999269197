import { useState, useEffect, Fragment, useCallback } from 'react'
import MenuItem from './MenuItem'
import ShoppingList from './ShoppingList'
import RecipePopup from './RecipePopup'
import {
  BACKEND_URL,
  isMobile,
  getWeekNumber,
  hasConsented,
  readLocally,
  saveLocally } from '../globals'
import './WeekPlan.scss'

function WeekMenu(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [plan, setPlan] = useState({})

  const fetchWeekPlan = useCallback(() => {
    fetchPlan('weekplan')
  }, [])

  useEffect(() => {
    const saved = readLocally('__gf_plan')

    if (saved === null ) {
      return fetchWeekPlan()
    } else {
      const thisWeek = getWeekNumber()
      if (thisWeek !== saved.week) {
        return fetchWeekPlan()
      }
      setPlan(saved)
    }
  }, [fetchWeekPlan])

  function fetchPlan(api) {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 10000) // Wait 10 sec

    setIsLoading(true)
    fetch(
      `${BACKEND_URL}/${api}`,
      { signal: controller.signal }
    ).then(response => {
      clearTimeout(timeoutId)
      return response.json()
    }).then(data => {
      setIsLoading(false)
      setPlan(data)
      saveLocally('__gf_plan', data)
    }).catch(error => {
      console.error('Could not retrieve this week plan')
      setIsLoading(false)
    })
  }

  function reloadPlan() {
    fetchPlan('weekplan?reload=true')
  }

  if (plan && Object.keys(plan).length === 0) {
    return (
      <section className="week-plan">
        <div className="message">
          <h1>Ooops!</h1>
          <p>It appears we are not able to retrieve this week menu</p>
        </div>
      </section>
    )
  }

  function selectMeal(e) {
    // Search the recipe in question in the current plan and mark it as selected
    const recipeId = e.target.id * 1  // string to int
    let found
    const newPlan = {...plan}
    newPlan.menus.find(elem => {
      const f = elem.recipes.find(item => item.id === recipeId)
      if (f) {
        found = f
        return true
      }
      return false
    })

    if (found) {
      found.selected = e.target.checked
      // If there are no ingredients for this recipe get them
      if (!found.ingredients) {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 10000) // Wait 10 sec

        fetch(
          `${BACKEND_URL}/recipe/${found.id}/ingredients`,
          { signal: controller.signal }
        ).then(response => {
          clearTimeout(timeoutId)
          return response.json()
        }).then(data => {
          found.ingredients = [...data]
          setPlan(newPlan)
          saveLocally('__gf_plan', newPlan)
        }).catch(error => {
          // If this happens the shopping list will not be accurate
          console.error('Could not retrieve the ingredients for this week plan')
          setPlan(newPlan)
          saveLocally('__gf_plan', newPlan)
        })
      } else {
        setPlan(newPlan)
        saveLocally('__gf_plan', newPlan)
      }
    }
  }

  // Extract all the ingredients of the selected recipes from the menus
  // of the meal plan. If there are duplicates add the quantity.
  function extractIngredientsFromMenus(menus) {
    if (!menus) {
      return []
    }

    const indexed = {}
    const result = []
    menus.forEach(day => {
      day.recipes.forEach(recipe => {
        if (recipe.selected) {
          recipe.ingredients.forEach(ri => {
            if (indexed[ri.name]) {
              indexed[ri.name].quantity += ri.quantity
            } else {
              indexed[ri.name] = {...ri}
            }
          })
        }
      })
    })

    for (const key in indexed) {
      result.push(indexed[key])
    }

    return result
  }

  if (isMobile) {
    return (
      <section className="week-plan-mobile">
        <h3>Meal Plan for week {plan.week}</h3>
        <span>&nbsp;&nbsp;({plan.from} - {plan.to})</span>
        {hasConsented() && (<span className="commands">
          <svg onClick={reloadPlan} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#777"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
        </span>)}
        <div className="plan-container-mobile">
          <article className="menus">
            {plan.menus.map(menu =>
              <MenuItem
                key={menu.day}
                id={menu.day}
                day={menu.day}
                recipes={menu.recipes}
                today={plan.today}
                onSelected={selectMeal}
                interactive={hasConsented()}
              />
            )}
          </article>
          <ShoppingList list={extractIngredientsFromMenus(plan.menus)}/>
        </div>
        <RecipePopup recipeid />
      </section>
    )
  }

  return (
    <section className="week-plan">
      <div className="plan-top">
        <h3>Meal Plan for week {plan.week}</h3>
        <span>&nbsp;&nbsp;({plan.from} - {plan.to})</span>
        {hasConsented() && (<span className="commands">
          <svg onClick={reloadPlan} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#777"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
        </span>)}
      </div>
      <div className="plan-container">
        <article className="menus">
          {isLoading
            ? (<Fragment><div></div><div className="spinner-container"><div className="spinner" style={{ display: isLoading ? 'block' : 'none' }} /></div><div/></Fragment>)
            : plan.menus.map(menu =>
            <MenuItem
              key={menu.day}
              id={menu.day}
              day={menu.day}
              recipes={menu.recipes}
              today={plan.today}
              onSelected={selectMeal}
              interactive={hasConsented()}
            />
          ) }
        </article>
        <ShoppingList list={extractIngredientsFromMenus(plan.menus)}/>
      </div>
      <RecipePopup recipeid />
    </section>
  )
}

export default WeekMenu
