import { Link } from 'react-router-dom'

function Footer(props) {
  return (
    <footer>
      <div className="footer-items">
        <div>
          <h4>Help</h4>
          <ul>
            <li><img alt="Email" width="18px" height="18px" src="icons/email_grey_18dp.svg"/><a href="mailto:hello@gaffel.dk" target="_blank" rel="noreferrer">hello@gaffel.dk</a></li>
          </ul>
        </div>
      </div>
      <div className="legal">
        <p>© 2022 All rights reserved. By using our website you agree to the use of cookies as outlined in our <Link to="/cookies">cookies policy</Link>.</p>
      </div>
    </footer>
  )
}

export default Footer
