import { useState, useEffect, Fragment } from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import DisplayRecipes from '../components/DisplayRecipes'
import RecipePopup from '../components/RecipePopup'
import { BACKEND_URL, MAX_NO_OF_TAGS, isMobile } from '../globals'
import './Leftover.scss'

function Leftover() {
  const [isLoading, setIsLoading] = useState(false)
  const [ingredients, setIngredients] = useState([])
  const [q, setQ] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [tags, setTags] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [error, setError] = useState(0)
  const [recipes, setRecipes] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 10000) // Wait 10 sec

    setIsLoading(true)
    fetch(
      `${BACKEND_URL}/ingredients`,
      { signal: controller.signal }
    ).then(response => {
      clearTimeout(timeoutId)
      return response.json()
    }).then(data => {
      setIsLoading(false)
      setIngredients(data)
    }).catch(error => {
      setIsLoading(false)
      console.error('Could not retrieve the ingredients')
    })
  }, [])

  if (!isLoading && Object.keys(ingredients).length === 0) {
    return (
      <Fragment>
        <Header />
        <section className="leftover">
          <div>
            <h1>Ooops!</h1>
            <p>Sorry, something wrong! We cannot provide this functionality at this time</p>
          </div>
        </section>
        <Footer />
      </Fragment>
    )
  }

  function onChange({ target }) {
    setQ(target.value)
    suggest(target.value)
  }

  function suggest(value) {
    setSuggestions([])

    if (value.length < 1) {
      return
    }

    const collect = []
    ingredients.forEach((i) => {
      if (value.length === 1) {
        // Match only the first letter
        if (i.toLowerCase().startsWith(value.toLowerCase())) {
          collect.push(i)
        }
      } else {
        // Match anywhere in the word
        if (i.toLowerCase().search(value.toLowerCase()) !== -1) {
          collect.push(i)
        }
      }
    })

    setSuggestions(collect)
  }

  function onSelectItem({ target }) {
    // Do not accept duplicates
    if (tags.length < MAX_NO_OF_TAGS) {
      const found = tags.find(elem => elem === target.innerText)
      if (!found) {
        setTags([...tags, target.innerText])
      }
    }
    setQ('')
    setSuggestions([])
  }

  function removeTag(tag) {
    const copy = tags.filter(item => item !== tag)
    setTags(copy)
  }

  function search() {
    if (tags.length < 1) {
      return
    }
    let uri = `${BACKEND_URL}/leftover?`
    for (let i = 0; i < tags.length; i++) {
      uri += `i${i + 1}=${tags[i]}&`
    }
    uri = uri.slice(0, -1)
    // Initiate spinner
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 10000) // Wait 10 sec
    setIsSearching(true)
    setError(0)
    fetch(uri, { signal: controller.signal }
    ).then(response => {
      clearTimeout(timeoutId)
      return response.json()
    }).then(data => {
      setIsSearching(false)
      setRecipes(data)
    }).catch(error => {
      setIsSearching(false)
      setError(1)
      console.error('Could not retrieve the recipes')
    })
  }

  return (
    <Fragment>
      <Header />
      <section className="leftover">
        <p>{isLoading ? 'Gathering the ingredients for our recipes. Please wait...' : 'Here you can tell us what food you have left and we will try to match them with our recipes.'}</p>

        <div className={isMobile ? 'tags mobile' : 'tags'}>
          {tags.map((t, i) => t &&
            <div className="tag" key={t}>
              {t}<img alt="" width="20px" height="20px" src="icons/close_black_24dp.svg" onClick={() => removeTag(t)}/>
            </div>
          )}
        </div>

        <div className={isMobile ? 'search-and-button-wrapper mobile' : 'search-and-button-wrapper'}>
          <article className="search-container">
            <input value={q} type="text" placeholder="Add ingredient" onClick={null} onChange={onChange} />
            <ul style={suggestions.length > 0 ? {'visibility': 'visible'} : {'visibility': 'hidden'} }>
              {suggestions.map((s, i) => s &&
                <li key={`sugg-${i}`} onClick={onSelectItem}>
                  <span>{s}</span>
                </li>
              )}
            </ul>
          </article>
          <button disabled={(tags.length < 1 || isSearching) ? true : false} onClick={search}>Search</button>
       </div>

       {(error !== 0) && <div className="message">
         <h1>Ooops!</h1>
         <p>Sorry, we cannot offer this service at this time. Try again later or contact hello@gaffel.dk</p>
       </div>}

       <DisplayRecipes recipes={recipes} highlight={new Set(tags)}/>

       <RecipePopup recipeid />

      </section>
      <Footer />
    </Fragment>
  )
}

export default Leftover
