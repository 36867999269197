import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import WeekPlan from '../components/WeekPlan'
import './Home.scss'
import { isMobile } from '../globals'

function HomePage() {

  return (
    <Fragment>
      <Header />
      <WeekPlan />
      <section className={isMobile ? 'value-proposition value-mobile' : 'value-proposition'}>
        <div className="proposition">
          <img alt="" width="40px" height="40px" src="icons/week_plan_grey_24dp.svg" />
          <h3>Week Meal Plan</h3>
          <p>Get inspired every week with our meal plan.</p>
        </div>
        <div className="proposition">
          <img alt="" width="40px" height="40px" src="icons/recycle_grey_24dp.svg" />
          <h3>Leftover</h3>
          <p>Got some leftover food in the refrigerator? Before throwing it away give it a chance to see if you can use it in one of our recipes <Link to='/leftover'>here</Link></p>
        </div>
      </section>
      <Footer />
    </Fragment>
  )
}

export default HomePage
