export const BACKEND_URL = process.env.NODE_ENV === 'production' ? 'https://api.gaffel.dk' : 'http://localhost:8090'
export const isMobile = window.matchMedia('(max-device-width: 700px)').matches
export const getDeviceSize = () => { return { height: window.innerHeight, width: window.innerWidth } }
export const MAX_NO_OF_TAGS = 5
export const getWeekNumber = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  now.setDate(now.getDate() + 4 - (now.getDay() || 7))
  return Math.ceil((((now - new Date(now.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7)
}

const consent = !!document.cookie.split(';').find(r =>r.trim()==='__gf_consent=true')
export const hasConsented = () => {
  return consent
}
//console.log('From globals consent = ' + consent)
export const saveLocally = (key, data) => {
  if (consent) {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const readLocally = (key) => {
  if (consent) {
    const txt = localStorage.getItem(key)
    if (txt === null) {
      return null
    }
    return JSON.parse(txt)
  }
  return null
}
