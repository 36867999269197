import { isMobile } from '../globals'
import './MenuItem.scss'

function MenuItem(props) {

  function showRecipe(event) {
    if (!event?.target?.attributes) {
      console.error('Event object is ivalid', event)
      return
    }

    const nameItem = event.target.attributes.getNamedItem('recipeid')
    let recipeId
    if (nameItem) {
      recipeId = nameItem.value
    } else {
      console.error('Could not find attribute recipeid of element', event.target.attributes)
      return null
    }

    // Emit event to popup
    const popupEvent = new CustomEvent('showpopuprecipe', {
      detail: {
        recipeid: recipeId,
        clientX: event.clientX,
        clientY: event.clientY,
        targetRect: event.target.getClientRects()[0]
      }
    })

    document.dispatchEvent(popupEvent)
  }

  return (
    <div className="menu-item">
      <h3 className={props.day === props.today ? 'today' : ''}>{props.day}</h3>
      {props.recipes.map( (item) =>
        <div key={item.id} className={isMobile ? 'item-wrapper mobile' : 'item-wrapper'}>
          {props.interactive && (<input checked={item.selected ? true : false} id={item.id} type="checkbox" onChange={props.onSelected}/>)}
          <p className={item.selected ? 'selected' : ''} recipeid={item.id} onClick={showRecipe}>{item.name}</p>
        </div>
      )}
    </div>
  )
}

export default MenuItem
